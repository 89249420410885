




























































































import { Component, Vue } from "vue-property-decorator";
import { IProjectCreate } from "@/interfaces";
import { dispatchCreateProject } from "@/store/main/actions";
import { MAP_PROJECT_MODES } from '@/utils';

@Component
export default class Create extends Vue {
  public valid = false;
  public modes = MAP_PROJECT_MODES;

  public project: IProjectCreate = {
    name: '',
    key: '',
    api_key: '',
    location: '',
    wifi: '',
    password: '',
    state: '',
    client: '',
    identification_code: '',
    mqtt: '',
    // is_active: true,
    has_local: false,
    energy_price: null,
    mode: 'normal',
  };

  public reset() {
    this.project = <IProjectCreate>{};
    this.$validator.reset();
  }

  public cancel() {
    this.$router.push("/main/projects/all");
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      if (this.project.energy_price === ""){
        this.project.energy_price = null;
      }
      await dispatchCreateProject(this.$store, this.project);
      this.$router.push("/main/projects/all");
    }
  }
}
